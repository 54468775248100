<template>
 
  <ion-content id="scroll-content" >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Agregar Siniestro </ion-title>
    <ion-form>
      <br>
      <div v-if="step == 1">
        <ion-label style="float: left; ">Ingrese el número de afectado</ion-label>
        <br>
        <ion-input type="number" min="1" v-model="num_clientes"  placeholder="Ingrese el número de afectado" />
        <ion-button color="dark" @click="generarAfectados">
          Enviar
        </ion-button>
      </div>
      
      <div v-if="step == 2">
        
        <ion-label style="float: left; ">Datos del afectado {{key_cliente+1}} </ion-label><br>
        
        <ion-input  list="browsers" type="text" v-model="clientes[key_cliente].nombre"  :placeholder="'Ingrese el nombre del afectado '+(key_cliente+1)"  >
          
        </ion-input>
        
        <!--<ion-fab>
          <ion-fab-button id="boton-fab" style="display: none;" ></ion-fab-button>
          <ion-fab-list style="margin-top: -6px;">
              <ion-list style="width: 300px; margin-left: -146px;">
                <ion-item v-for="cliente in list_clientes" :key="cliente">
                  <ion-label>{{cliente.name}}</ion-label>
                </ion-item>
            </ion-list>
          </ion-fab-list>
        </ion-fab>-->

        <ion-input type="text" v-model="clientes[key_cliente].telefono"  :placeholder="'Ingrese el telefono del afectado '+(key_cliente+1)" />
        <ion-input type="text" v-model="clientes[key_cliente].movil"  :placeholder="'Ingrese el movil del afectado '+(key_cliente+1)" />
       

        <ion-button color="dark" @click="goNextCliente">
          Enviar
        </ion-button>

        <ion-button color="primary" @click="goBackCliente">
          Atras
        </ion-button>

      </div>
     
      <div v-if="step == 3">
        
        <div v-if="getUser?.role?.name == 'Despacho'">
          <ion-label style="float: left; ">Seleccione el colaborador</ion-label><br>
          
          <ion-select class="input-text"  interface="action-sheet" v-model="colaborador_id" style="color: #000;" > 
            <ion-select-option v-for="colaborador in colaboradores" :value="colaborador.id" :key="colaborador" >{{colaborador.name == null ?  colaborador.user_name : colaborador.name }}</ion-select-option>
          </ion-select>
        </div>
        
        <ion-label style="float: left; ">Número de expediente</ion-label><br>
        <ion-input type="text"  v-model="expediente" placeholder="Ingrese número de expediente (opcional)" />

        <ion-label style="float: left; ">Version del sucedido</ion-label><br>
        <ion-textarea type="text"  v-model="version_suceso" placeholder="Ingrese version del sucedido" />
      
        <ion-label style="float: left; ">Observaciones</ion-label><br>
        <ion-textarea type="text"  v-model="observacion" placeholder="Ingrese la observación" />
        
        <ion-button v-if="getUser?.role?.name == 'Despacho'" color="dark" @click="postSiniestroDespacho">
          Enviar
        </ion-button>

        <ion-button v-if="getUser?.role?.name == 'Colaborador'" color="dark" @click="postSiniestro">
          Enviar
        </ion-button>

        <ion-button color="primary" @click="step = 2">
          Atras
        </ion-button>
      </div>

    
    </ion-form>
  </div>
</ion-content>



</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput,IonTextarea,IonSelect} from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'
import { mapGetters } from "vuex";
import 'bootstrap';

export default defineComponent({
  name: 'AgregarColaborador',
  components :{IonInput,IonTextarea,IonSelect},
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  setup() {
    return { close }
  },
  data(){
    return{
      step : 1,
      num_clientes : 1,
      key_cliente : 0,
      clientes : [],
      list_clientes : [],
      afectados : [],
      version_suceso: null,
      observacion: null,
      expediente : null,
      click_active : false,
      colaboradores : [],
      colaborador_id : null
    }
  },
  mounted(){
    if (this.getUser?.role?.name == 'Despacho') {
      this.getColaboradoresByUser()
    }
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    goBackCliente(){
      if ((this.key_cliente+1) == 1) {
        this.step = 1
      }else{
        this.key_cliente--;
      }
    },
    goNextCliente(){
      if ((this.key_cliente+1) == this.num_clientes) {
        this.step = 3
      }else{
        this.key_cliente++;
      }
    },
    event($event){
      this[$event.target.id] = $event.target.value
    },
  
    searchClientes(text){
     
      axios.get('/api/users/search/cliente?filter_like='+text)
      .then(res => {
        console.log(res.data)
        this.list_clientes = res.data.data
        if (this.list_clientes != 0) {
            document.querySelector('#boton-fab').click()
            this.click_active  = true
        }else{
          document.querySelector('#boton-fab').click()
          this.click_active  = false
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    generarAfectados(){
      this.clientes = [];

      for (var i = 0; i < parseInt(this.num_clientes); i++) {
        this.clientes[i]= {
          nombre : '',
          telefono : '',
          movil : '',
        }
      }

      this.step = 2
    },
    getColaboradoresByUser(){
       axios.get('/api/users/colaborador/byUser?all=true')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    async postSiniestro(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/siniestros',{
        clientes : this.clientes,
        version_suceso: this.version_suceso,
        observacion: this.observacion,
        expediente : this.expediente
      })
      .then(data => {
         loading.dismiss()
         this.closeModal()
         toast.openToast("Registro de siniestro exitoso","success",2000);
        console.log(data)
      }).catch(err => {
        loading.dismiss()
        console.log(err)
      })
    },
    async postSiniestroDespacho(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/siniestros/by/despacho',{
        clientes : this.clientes,
        version_suceso: this.version_suceso,
        observacion: this.observacion,
        expediente : this.expediente,
        colaborador_id : this.colaborador_id
      })
      .then(data => {
         loading.dismiss()
         this.closeModal()
         toast.openToast("Registro de siniestro exitoso","success",2000);
        console.log(data)
      }).catch(err => {
        loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>
<style type="text/css">



ion-content:part(scroll){
 background-color: rgba(0, 0, 0, 0.3);
}

 @media (min-width: 320px) and (max-width: 767px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 90% !important;
      transform-origin: right top !important;
    }
    
  }  

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover, .sc-ion-select-popover-h ion-label.sc-ion-select-popover{
      text-align: center;
}
 @media (min-width: 768px) and (max-width: 1500px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 38% !important;
      transform-origin: right top !important;
      text-align: right !important;
    }
    
  } 


ion-radio::part(container){
  display: none;
}
ion-select::part(text){

}

</style>
<style scoped>

.dropdown.dropdown-scroll .dropdown-list{
    max-height: 233px;
    overflow-y: auto;
    list-style-type: none;
    padding-left: 10px;
    margin-bottom: 0px;
}
.dropdown-list  li{
    font-size: 14px;
    height: 20px;
}

.dropdown-list  li > a{
    color: black;
}
.dropdown-list a:hover{
   color: black;
}

.dropdown-list li:hover{
    background-color: lightgray;
}

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
