<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <div style="margin-left: 24px;margin-right: 24px;">
        <ion-row>
         
          <ion-col sizeLg="4" sizeMd="4" sizeXs="12" >
              <ion-searchbar  
                @ionChange="getSiniestrosSearchByUser" 
                v-model="search" 
                show-cancel-button="focus" 
                debounce="1000" 
                placeholder="Buscar siniestro" 
                cancel-button-text="Custom Cancel"
              >
             </ion-searchbar>
          </ion-col>
          
          <ion-col>
          
            <ion-select 
              class="input-text" 
              placeholder="Filtrar por Status" 
              interface="action-sheet" 
              @ionChange="getSiniestrosSearchByUser" 
              v-model="status" 
              style="color: #000;" 
            > 
              <ion-select-option value="">Todos</ion-select-option>
              <ion-select-option value="true">No Leido</ion-select-option>
              <ion-select-option value="false">Leido</ion-select-option>
            </ion-select>
          </ion-col>
          
          <ion-col>
            <ion-select 
              class="input-text" 
              placeholder="Filtrar por Status" 
              interface="action-sheet" 
              @ionChange="getSiniestrosSearchByUser" 
              v-model="colaborador_id" 
              style="color: #000;" 
            > 
              <ion-select-option value="">Colaborador</ion-select-option>
              <ion-select-option 
                v-for="colaborador in colaboradores" 
                :value="colaborador.id" 
                :key="colaborador" 
              >
                {{
                  colaborador.name == null ?  
                  colaborador.user_name : 
                  colaborador.name 
                }}
              </ion-select-option>
            </ion-select>
          </ion-col>

          <ion-col>  
            <ion-button 
              v-if="getUser?.role?.name == 'Colaborador' || getUser?.role?.name == 'Despacho'"
              color="dark" 
              style="width: 45px;" 
              @click="openModalAdd"
            >
              +
            </ion-button>
          </ion-col>
        
        </ion-row>

        <div class="card">
            <table class="table table-responsive-sm table-striped table-align-middle">
              <thead>
                <tr>
                  <th @click="setSort('siniestros.expediente')">
                    Nro. expediente 
                    <ion-icon v-if="sort_active && sort == 'siniestros.expediente' && order == 'asc'" :icon="arrowUpOutline"></ion-icon>
                    <ion-icon v-if="sort_active && sort == 'siniestros.expediente' && order == 'desc'" :icon="arrowDownOutline"></ion-icon> 
                  </th>

                  <th @click="setSort('users.user_name')">
                    Colaborador
                    <ion-icon v-if="sort_active && sort == 'users.user_name' && order == 'asc'" :icon="arrowUpOutline"></ion-icon>
                    <ion-icon v-if="sort_active && sort == 'users.user_name' && order == 'desc'" :icon="arrowDownOutline"></ion-icon> 
                  </th>

                  <th style="width: 300px;">Clientes</th>
                  <th @click="setSort('siniestros.created_at')">
                    Fecha de registro
                    <ion-icon v-if="sort_active && sort == 'siniestros.created_at' && order == 'asc'" :icon="arrowUpOutline"></ion-icon>
                    <ion-icon v-if="sort_active && sort == 'siniestros.created_at' && order == 'desc'" :icon="arrowDownOutline"></ion-icon> 
                  </th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <DataSiniestro :siniestros="siniestros"/>
              
            </table>
            <Paginate :data="siniestros" @page="page($event)"/>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import { defineComponent } from 'vue';

import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonSelect, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonSearchbar 
} from '@ionic/vue';

import { 
  modalController,
  createAnimation,
  alertController 
} from '@ionic/vue';

import { mapGetters } from "vuex";

import toast from '@/plugins/toast'
import axios from 'axios'
import agregarSiniestro from './agregarSiniestro';
import DataSiniestro from '@/components/DataSiniestro'
import Paginate from '@/components/Paginate'

export default defineComponent({
  name: 'FolderPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonSelect,
    DataSiniestro,
    Paginate
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return {
      localStorage: 'page_siniestro_reclasin',
      siniestros : [],
      colaboradores: [],
      colaborador_id: '',
      sort: '',
      sort_prev: null,
      sort_active : false,
      order: 'desc',
      search: '' ,
      status: ''
    }
  },
  mounted(){
    this.getColaboradoresByUser()
    this.getUser.role.name != 'Administrador' ? 
      this.getSiniestrosByUser() : 
      this.getSiniestros();
  },
  methods:{
    setSort(column){
      this.sort = column
      this.sort_active = true

      if (this.sort == column) {
        if(this.sort_prev == column)
          this.order == 'asc' ? this.order = 'desc' : this.order = 'asc'
      }else{
        this.sort = column
      }
      this.sort_prev = column
      
      this.getUser.role.name != 'Administrador' ? 
        this.getSiniestrosSearchByUser() : 
        this.getSiniestrosSearch();
    },
    getSiniestros(){
      axios.get('/api/siniestros?finalizado=0')
      .then(res => {
        console.log(res.data.data)
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestrosSearch(){
      axios.get('/api/siniestros?finalizado=0&search='+this.search+'&columnOrderBy='+this.sort+'&orderBy='+this.order+'&status='+this.status+'&colaborador_id='+this.colaborador_id)
      .then(res => {
        console.log(res.data.data)
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestrosByUser(){
      axios.get('/api/siniestros/by/user?finalizado=0')
      .then(res => {
        console.log(res.data.data)
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestrosSearchByUser(){
      axios.get('/api/siniestros/by/user?finalizado=0&search='+this.search+'&columnOrderBy='+this.sort+'&orderBy='+this.order+'&status='+this.status+'&colaborador_id='+this.colaborador_id)
      .then(res => {
        console.log(res.data.data)
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getColaboradoresByUser(){
       axios.get('/api/users/colaborador/byUser?all=true')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    page(url){
    
      if (url == null) {
        return;
      }

      //var page = this.getParameterByName("page",url);

      axios.get(url+'&finalizado=0&search='+this.search+'&columnOrderBy='+this.sort+'&orderBy='+this.order+'&status='+this.status+'&colaborador_id='+this.colaborador_id)
       .then(res => {
         this.siniestros = res.data.data;
       })
       .catch(error => {
        console.log(error);
      });
    },
     async openModalAdd() {
      
      const modal = await modalController
        .create({
          component: agregarSiniestro,
          cssClass: 'class-modal',
          keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
        })
      
      modal.present();

      modal.onDidDismiss().then((data) => {
        this.getUser.role.name != 'Administrador' ? 
          this.getSiniestrosByUser() : 
          this.getSiniestros();
        console.log(data)
      })
    },
    showNota(siniestro){
      //location.href = 'chat/'+siniestro.chat.id+'?siniestro_id='+siniestro.id
      window.open('chat/'+siniestro.chat.id+'?siniestro_id='+siniestro.id, "Notas Siniestros "+siniestro.expediente, "width=800, height=800")
    },
    showDocumentos(siniestro_id){
      //this.$router.push({ name: 'documentos' , params : {siniestro_id : siniestro_id}})
      window.open('documentos/'+siniestro_id, "Documentos Siniestros ", "width=800, height=800")
    },
    async eliminarSiniestroConfirmacion(siniestro) {

        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: '¡Confirmación!',
          message: '¿Realmente desea eliminar este siniestro?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel:', blah);
              },
            },
            {
              text: 'Eliminar',
              handler: async () => {
                var loading = await toast.showLoading()

                await loading.present();
                
                console.log('Confirm Okay');
                
                axios.delete('/api/siniestros/'+siniestro.id)
                .then(data =>{
                  toast.openToast("Siniestro eliminado exitosamente","error",2000)
                  loading.dismiss()
                  this.getUser.role.name != 'Administrador' ? this.getSiniestrosByUser() : this.getSiniestros();
                  console.log(data)

                }).catch(err =>{
                  loading.dismiss()
                  console.log(err)
                })
              },
            },
          ],
        });
        return alert.present();
      },
   
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}

.input-text{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :90%;
  height : 48px;
}


</style>